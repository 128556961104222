import request from '../utils/request'

export function getInvoiceList(params={}) {
  return request({
      method: 'GET',
      url:'invoice/list',
      params
  })
}

export function basicData() {
  return request({
      method: 'GET',
      url:'invoice/basicData'
  })
}

export function submitInvoice(data) {
    return request({
        method: 'POST',
        url: 'invoice/submit',
        data
    })
}